import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  createRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames';
import {
  accountActions,
  registerVehicles,
  updateUserShop,
} from 'slices/accountSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import {
  fetchServiceSelectInit,
  fetchSubShop,
  actions,
} from 'slices/reservationShopAndServiceSelectSlice';
import { displayCautionActions } from 'slices/displayCautionSlice';
import Yup from 'utils/yupUtil';
import { scrollTo } from 'utils/otherUtil';
import { sliceByNumberForFill } from 'utils/arrayUtil';
import Icons from 'images/icons';
import Stepper from 'components/stepper';
import InputSelect from 'components/inputSelect';
import Proctitle from 'components/proctitle';
import AprosButton from 'components/aprosButton';
import Map from 'components/map';
import RadioButton from 'components/radioButton';
import Checkbox from 'components/checkbox';
import NotesModal from 'components/messageModals/notesModal';
import ShopSelectBottomItem from './components/shopSelectBottomItem';
import UserNameLabel from './components/userNameLabel';
import MapLink from './components/mapLink';
import VehicleRegisterModal from './modals/vehicleRegisterModal';
import ShopUpdateModal from './modals/shopUpdateModal';
import prefecturesList from 'constants/prefectures';
import { REGISTER_SERVICE_STEP } from 'constants/reservation';
import Path, { ERROR_MESSAGE_TRANSITION } from 'constants/routePath';
import {
  MULTI_SELECT_SERVICE,
  NO_SMALL_SELECT_SERVICE,
  SERVICE_CODE_TIRE,
  TIRE_SWITCH_LABEL,
  TIRE_CATEGORY_CODE_WHEEL,
  TIRE_CATEGORY_CODE_WHEEL_LABEL,
  TIRE_CATEGORY_CODE_TIRE,
  TIRE_CATEGORY_CODE_TIRE_LABEL,
  SERVICE_SELECT_TRANSITION_TYPE,
  SERVICE_SELECT_MAIN_LABEL,
} from 'constants/service';
import { useDeviceSize, DEVICE_TYPE } from 'hooks/useDeviceSize';
import { useShopAndServiceSelectParams } from './hooks/useShopAndServiceSelectParams';
import { getAllChunbunCd, getCurrentService } from './utils/reservationUtil';
import { useConfirmHistoryBack } from 'hooks/useConfirmHistoryBack';
import { Helmet } from 'react-helmet';
import Title from '../../constants/title';
import { RankDescription } from 'constants/rank';

const COLUMN_COUNT = {
  [DEVICE_TYPE.PC]: 4,
  [DEVICE_TYPE.TABLET]: 3,
  [DEVICE_TYPE.SP]: 2,
};

const ShopAndServiceSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCurrentPlaceDisabled, setIsCurrentPlaceDisabled] = useState(false);
  const [isAroundPlaceDisabled, setIsAroundPlaceDisabled] = useState(false);

  // 検索で使用する位置情報
  const [currentLocation, setCurrentLocation] = useState(null);
  // マップの中央位置
  const [mapCenter, setMapCenter] = useState(null);
  // 選択中のマーカー（店舗CD）
  const [selectMarker, setSelectMarker] = useState(null);
  // 編集したらブラウザバックで警告を表示する
  const [isEdited, setIsEdited] = useState(false);
  useConfirmHistoryBack({ isEdited, setIsEdited });

  const { isPc, isSp, deviceType } = useDeviceSize();

  const transitionParams = useShopAndServiceSelectParams();
  const {
    previousStep,
    paramShopCode,
    tokCd,
    chubunCd,
    shobunCd,
    transitionPattern,
    yykInfo,
    baseChubunCd,
    selectedSrySeq: prevSelectedSrySeq,
  } = transitionParams;

  const {
    accountInfo: { rankCode },
  } = useSelector(state => state.account);

  // 上部エリアのラベル名
  const titleLabelName = SERVICE_SELECT_MAIN_LABEL[transitionPattern];
  // 下エリア表示するか
  const showMapArea = ![
    SERVICE_SELECT_TRANSITION_TYPE.COMPLETE,
    SERVICE_SELECT_TRANSITION_TYPE.KARTE,
  ].includes(transitionPattern);
  // 外部遷移か
  const isExternal = [
    SERVICE_SELECT_TRANSITION_TYPE.BRAND_WORK,
    SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO,
    SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO_WORK,
  ].includes(transitionPattern);

  const setRef = useCallback(
    node => {
      if (!node) return;

      dispatch(actions.setMapAreaHeight(node?.offsetHeight ?? 0));
    },
    [dispatch]
  );

  const setSubAreaRef = useCallback(
    node => {
      if (!node) return;

      const shopCode = node.id.split('_')[1];
      dispatch(
        actions.setSubAreaHeight({
          height: node?.offsetHeight,
          shopCode: shopCode,
        })
      );
    },
    [dispatch]
  );

  const {
    mapAreaHeight,
    subAreaHeight,
    selectedSrySeq,
    mainShopInfo,
    subShopInfoList,
    allServiceList,
    serviceList,
    subShopService,
    showVehicleRegisterModal,
    showShopChangeModal,
    carList,
    shopList,
    shopStatus,
    isSubShopLoading,
  } = useSelector(state => state.reservationShopAndServiceSelect);

  const { vehicles, accountInfo, updateSrySeq } = useSelector(
    state => state.account
  );

  const { userId, cardNumber } = accountInfo || {};

  const subShopRefs = useRef([]);

  const mapRef = useRef();

  const showSubShopInfoList = subShopInfoList;

  showSubShopInfoList?.forEach((_, index) => {
    subShopRefs.current[index] = createRef();
  });

  const formik = useFormik({
    initialValues: {
      prefectures: null,
      serviceMain: null,
      serviceSub: null,
      subShopServiceMain: {},
      subShopServiceSub: {},
    },
    validateOnMount: true,
    validationSchema: Yup.object({}),
  });

  const {
    prefectures,
    serviceMain,
    serviceSub,
    subShopServiceMain,
    subShopServiceSub,
  } = formik.values;

  useEffect(() => {
    if (!dispatch) return;

    dispatch(displayCautionActions.check());
    dispatch(
      fetchServiceSelectInit({
        shopCode: paramShopCode || accountInfo.shopCode,
      })
    );

    if (showMapArea) {
      // 初期位置設定
      if (yykInfo?.paramPrefectures) {
        // 引数で都道府県が選択されていた場合
        const { lat, lng, value } = prefecturesList.find(
          p => p.value === yykInfo?.paramPrefectures
        );
        setMapCenter({ lat, lng });
        formik.setFieldValue('prefectures', value);
      } else if (yykInfo?.location) {
        // 引数で位置情報が選択されていた場合
        updateLocation(yykInfo?.location);
      }
    }

    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    // 引数で位置情報が選択されていた場合はアップデートしない
    if (yykInfo?.location) {
      return;
    }

    // 初期位置は引数の店舗
    if (shopList && shopList.length > 0) {
      const targetShop = shopList.find(
        s => s.shopCode === (paramShopCode || accountInfo.shopCode)
      );
      if (targetShop?.location) {
        const [lng, lat] = targetShop.location;

        updateLocation({ lat, lng });
      }
    }
  }, [shopList]);

  useEffect(() => {
    if (
      !allServiceList ||
      allServiceList.length === 0 ||
      !isExternal ||
      !chubunCd
    )
      return;

    if (!getAllChunbunCd(allServiceList).includes(chubunCd)) {
      dispatch(
        messageModalActions.showMessageModal({
          message: ERROR_MESSAGE_TRANSITION,
        })
      );
      navigate(Path.TOP);
    }
  }, [allServiceList, isExternal, chubunCd]);

  useEffect(() => {
    // サービス取得前
    if (!serviceList || serviceList.length === 0) return;

    // 中分類コードが指定されていない
    if (!chubunCd) return;

    // 選択対象の店舗がメイン店舗と違う場合
    if (tokCd && (paramShopCode || accountInfo.shopCode) !== tokCd) return;

    const filterService = serviceList.filter(s =>
      s.aprosSubCategories.some(c =>
        c.codeCombinations.some(b => b.chubunCd === chubunCd)
      )
    );

    // 中分類コード/小分類コードを選択状態にする
    for (const service of serviceList) {
      for (const aprosSubCategory of service.aprosSubCategories) {
        for (const codeCombination of aprosSubCategory.codeCombinations) {
          // 中分類コードが一致しない
          if (codeCombination.chubunCd !== chubunCd) continue;

          if (shobunCd && shobunCd.length > 0) {
            // 小分類コードまで指定されている場合
            if (
              aprosSubCategory.codeCombinations
                .map(com => com.shobunCd)
                .filter(s => shobunCd.indexOf(s) > -1).length > 0
            ) {
              formik.setFieldValue('serviceMain', service.aprosCode);
              const serviceSub = service.aprosSubCategories
                .filter(
                  sub =>
                    sub.codeCombinations.filter(
                      com =>
                        chubunCd === com.chubunCd &&
                        shobunCd.indexOf(com.shobunCd) > -1
                    ).length > 0
                )
                .map(s => s.aprosSmallCode);
              const sub = serviceSub.length > 1 ? serviceSub : serviceSub[0];
              formik.setFieldValue('serviceSub', sub);
              return;
            }
          } else if ('4' !== codeCombination.convertPattern) {
            if (
              filterService.length > 1 &&
              chubunCd === '003' &&
              service.aprosCode === '007'
            ) {
              continue;
            }

            // 小分類コードが指定されていない and 変換パターン4以外の場合
            formik.setFieldValue('serviceMain', service.aprosCode);
            formik.setFieldValue(
              'serviceSub',
              subServiceInitValue(service.aprosCode, serviceList)
            );
            return;
          }
        }
      }
    }
  }, [dispatch, serviceList]);

  useEffect(() => {
    // サービス取得前
    if (!subShopService || Object.keys(subShopService).length === 0) return;

    // 中分類コードが指定されていない
    if (!chubunCd) return;

    // 選択対象の店舗がメイン店舗の場合
    if (!tokCd || tokCd === (paramShopCode || accountInfo.shopCode)) return;

    // 選択対象の店舗が表示対象ではない場合
    if (!Object.keys(subShopService).includes(tokCd)) return;

    // 中分類コード/小分類コードを選択状態にする
    for (const service of subShopService[tokCd]) {
      for (const aprosSubCategory of service.aprosSubCategories) {
        for (const codeCombination of aprosSubCategory.codeCombinations) {
          // 中分類コードが一致しない
          if (codeCombination.chubunCd !== chubunCd) continue;

          if (shobunCd && shobunCd.length > 0) {
            // 小分類コードまで指定されている場合
            if (
              aprosSubCategory.codeCombinations
                .map(com => com.shobunCd)
                .filter(s => shobunCd.indexOf(s) > -1).length > 0
            ) {
              formik.setFieldValue('subShopServiceMain', {
                ...subShopServiceMain,
                [tokCd]: service.aprosCode,
              });
              const serviceSub = service.aprosSubCategories
                .filter(
                  sub =>
                    sub.codeCombinations.filter(
                      com =>
                        chubunCd === com.chubunCd &&
                        shobunCd.indexOf(com.shobunCd) > -1
                    ).length > 0
                )
                .map(s => s.aprosSmallCode);
              const sub = serviceSub.length > 1 ? serviceSub : serviceSub[0];
              formik.setFieldValue('subShopServiceSub', {
                ...subShopServiceSub,
                [tokCd]: sub,
              });
              return;
            }
          } else if ('4' !== codeCombination.convertPattern) {
            // 小分類コードが指定されていない and 変換パターン4以外の場合
            formik.setFieldValue('subShopServiceMain', {
              ...subShopServiceMain,
              [tokCd]: service.aprosCode,
            });

            // 初期値を設定
            const content = { ...subShopServiceSub };
            content[tokCd] = subServiceInitValue(
              service.aprosCode,
              subShopService[tokCd]
            );
            formik.setFieldValue('subShopServiceSub', content);
            return;
          }
        }
      }
    }
  }, [dispatch, subShopService]);

  useEffect(() => {
    if (vehicles.length > 0) {
      if (!selectedSrySeq) {
        // 初期車両設定
        dispatch(
          actions.setSrySeq(
            yykInfo?.srySeq || prevSelectedSrySeq || vehicles[0].carSeq
          )
        );
      } else if (updateSrySeq) {
        // 車両を追加した場合、追加車両を選択状態にする
        dispatch(actions.setSrySeq(updateSrySeq));
        dispatch(accountActions.removeUpdateSrySeq());
      }
    }
  }, [dispatch, vehicles]);

  useEffect(() => {
    if (!shopStatus) return;
    if (shopStatus !== '0') {
      dispatch(actions.showShopChangeModal());
    } else if (vehicles.length === 1 && !vehicles[0].modelName) {
      // 未登録車両一つの場合、登録モーダルを表示する
      dispatch(actions.showVehicleRegisterModal());
    }
  }, [dispatch, shopStatus]);

  useEffect(() => {
    showMapArea &&
      currentLocation &&
      allServiceList.length > 0 &&
      !prefectures &&
      dispatch(
        fetchSubShop({
          searchParams: {
            longitude: currentLocation.lng,
            currentLongitude: currentLocation.lng,
            latitude: currentLocation.lat,
            currentLatitude: currentLocation.lat,
          },
          chubunCd: isExternal ? baseChubunCd : null,
        })
      );
  }, [currentLocation, allServiceList]);

  useEffect(() => {
    showMapArea &&
      allServiceList.length > 0 &&
      prefectures &&
      dispatch(fetchSubShop({ prefectures, chubunCd }));
  }, [prefectures, allServiceList]);

  const { shopCode, location } = showSubShopInfoList[0] ?? {};

  useEffect(() => {
    if (shopCode) {
      const [lng, lat] = location;
      setMapCenter({
        lat,
        lng,
      });
    }
  }, [shopCode, location]);

  // 位置情報を更新する
  const updateLocation = ({ lat, lng }) => {
    setCurrentLocation({ lat, lng });
    setMapCenter({ lat, lng });
  };

  const serviceSliceList = sliceByNumberForFill(
    serviceList,
    COLUMN_COUNT[deviceType]
  );

  // サブカテゴリの初期値を判定する
  const subServiceInitValue = (mainCode, list) => {
    const main = list.find(s => mainCode === s.aprosCode);
    if (MULTI_SELECT_SERVICE.includes(main.aprosCode)) {
      // 複数選択可能な場合、空配列を設定
      return [];
    } else if (!NO_SMALL_SELECT_SERVICE.includes(main.aprosCode)) {
      // 単数選択の場合、nullを設定
      return null;
    } else {
      // 小カテゴリ選択肢が単一の場合、先頭を設定
      return main.aprosSubCategories[0].aprosSmallCode;
    }
  };

  // 現在位置取得
  const getCurrentPosition = () => {
    // ボタン非活性
    setIsCurrentPlaceDisabled(true);

    // 500ms待ち
    setTimeout(() => {
      setIsCurrentPlaceDisabled(false);
    }, 500);
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        updateLocation({ lat: latitude, lng: longitude });
      },
      () => {
        dispatch(
          messageModalActions.showMessageModal({
            message:
              '位置情報の取得が許可されていません。\nブラウザの設定を見直してください。',
          })
        );
      }
    );
  };

  const button = (item, i) => {
    const { aprosCode, aprosCategoryName, aprosImageUrl } = item || {};
    return !item ? (
      <div key={`button_${i}`} className="w-full"></div>
    ) : (
      <label key={aprosCode} className="w-full">
        <input
          type="radio"
          name={'serviceMain'}
          value={aprosCode}
          checked={serviceMain === aprosCode}
          onChange={e => {
            setIsEdited(true);
            formik.handleChange(e);
            formik.setFieldValue(
              'serviceSub',
              subServiceInitValue(e.target.value, serviceList)
            );
          }}
          className="peer absolute opacity-0"
        />
        <span
          className={classNames(
            'flex h-[64px] w-full cursor-pointer items-center justify-center rounded-xl font-bold tracking-[.06em] drop-shadow-[2px_2px_3px_#00244529] sm:h-[50px] sm:drop-shadow-[3px_3px_6px_#00244529] lg:h-16',
            'border-[0.5px] border-[#E5E5E5] bg-white text-navy lg:hover:bg-[#EEF0F2]',
            'peer-checked:bg-navy peer-checked:text-white'
          )}
        >
          <div
            className={classNames(
              'flex w-full items-center justify-start gap-1 text-[14px] lg:text-[16px]',
              isSp ? 'pl-2 pr-1' : 'pl-3'
            )}
          >
            <Icons.Image
              src={
                serviceMain === aprosCode
                  ? aprosImageUrl.onClickIcon.includes('http')
                    ? aprosImageUrl.onClickIcon
                    : Icons[aprosImageUrl.onClickIcon]
                  : aprosImageUrl.normalIcon.includes('http')
                  ? aprosImageUrl.normalIcon
                  : Icons[aprosImageUrl.normalIcon]
              }
              className="w-10"
              alt={aprosCategoryName}
            />
            {aprosCategoryName}
          </div>
        </span>
      </label>
    );
  };

  const createSubCategoryLabel = (
    mainCode,
    { aprosSmallCode, aprosSmallName, aprosSmallImageUrl },
    colorClass
  ) =>
    SERVICE_CODE_TIRE === mainCode &&
    [TIRE_CATEGORY_CODE_WHEEL, TIRE_CATEGORY_CODE_TIRE].includes(
      aprosSmallCode
    ) ? (
      <div className="flex flex-row items-center">
        <p
          className={classNames(
            'text-sm',
            {
              [TIRE_CATEGORY_CODE_WHEEL]: 'pr-[155px] sm:pr-[186px]',
              [TIRE_CATEGORY_CODE_TIRE]: 'pr-[143px] sm:pr-[170px]',
            }[aprosSmallCode]
          )}
        >
          {TIRE_SWITCH_LABEL}
        </p>
        <p
          className={classNames(
            'text-xs absolute ml-[80px] flex items-center px-2 font-normal sm:ml-[100px]',
            colorClass
          )}
        >
          {
            {
              [TIRE_CATEGORY_CODE_WHEEL]: TIRE_CATEGORY_CODE_WHEEL_LABEL,
              [TIRE_CATEGORY_CODE_TIRE]: TIRE_CATEGORY_CODE_TIRE_LABEL,
            }[aprosSmallCode]
          }
          <Icons.Image src={aprosSmallImageUrl} className="ml-2 h-7 w-7" />
        </p>
      </div>
    ) : (
      aprosSmallName
    );

  const serviceSubElement = item => {
    return MULTI_SELECT_SERVICE?.includes(serviceMain) || false
      ? serviceSubCheckbox(item)
      : serviceSubRadioButton(item);
  };

  const serviceSubRadioButton = ({
    aprosSmallCode,
    aprosSmallName,
    aprosSmallImageUrl,
  }) => (
    <RadioButton
      key={aprosSmallCode}
      formik={formik}
      fieldName={'serviceSub'}
      label={createSubCategoryLabel(
        serviceMain,
        { aprosSmallCode, aprosSmallName, aprosSmallImageUrl },
        'bg-background-sub'
      )}
      value={aprosSmallCode}
      className={{ labelClass: 'mx-0' }}
      onChange={e => {
        setIsEdited(true);
        formik.setFieldValue('serviceSub', e.target.value);
        setTimeout(() => formik.setFieldTouched('serviceSub', true));
      }}
    />
  );

  const serviceSubCheckbox = ({ aprosSmallCode, aprosSmallName }) => (
    <Checkbox
      key={aprosSmallCode}
      fieldName={aprosSmallCode}
      text={aprosSmallName}
      value={serviceSub?.includes(aprosSmallCode)}
      className={{ labelClass: 'mx-0' }}
      onChange={() => {
        setIsEdited(true);
        if (!serviceSub.includes(aprosSmallCode)) {
          const newList = [...serviceSub];
          newList.push(aprosSmallCode);
          formik.setFieldValue('serviceSub', newList);
        } else {
          formik.setFieldValue(
            'serviceSub',
            serviceSub.filter(l => l !== aprosSmallCode)
          );
        }
      }}
    />
  );

  const subShopServiceMainRadioButton = (item, shopCode) => (
    <RadioButton
      fieldName={`subShopServiceMain_${shopCode}`}
      label={item.aprosCategoryName}
      checked={() => subShopServiceMain[shopCode] === item.aprosCode}
      value={`${shopCode}_${item.aprosCode}`}
      onChange={() => {
        setIsEdited(true);
        formik.setFieldValue('subShopServiceMain', {
          ...subShopServiceMain,
          [shopCode]: item.aprosCode,
        });

        // 小カテゴリ初期値を設定
        const content = { ...subShopServiceSub };
        if (MULTI_SELECT_SERVICE.includes(item.aprosCode)) {
          // 複数選択可能な場合、空配列を設定
          content[shopCode] = [];
        } else if (!NO_SMALL_SELECT_SERVICE.includes(item.aprosCode)) {
          // 単数選択の場合、nullを設定
          content[shopCode] = null;
        } else {
          // 小カテゴリ選択肢が単一の場合、先頭を設定
          content[shopCode] = item.aprosSubCategories[0].aprosSmallCode;
        }
        formik.setFieldValue('subShopServiceSub', content);
      }}
      className={{ labelClass: 'mx-0' }}
    />
  );

  const subShopSubArea = (item, shopCode) =>
    subShopServiceMain[shopCode] === item.aprosCode &&
    !NO_SMALL_SELECT_SERVICE.includes(item.aprosCode) && (
      <>
        {/* 高さ調整用 */}
        <span
          className="mb-4 sm:mb-0"
          style={{ height: subAreaHeight[shopCode] }}
        />
        {/* チェックボックス部分 */}
        <div
          id={`subArea_${shopCode}`}
          ref={element => setSubAreaRef(element)}
          className="absolute left-0 mt-12 h-auto w-full border border-line bg-background-main px-4 sm:px-6"
        >
          <div className="flex flex-col flex-wrap gap-x-6 whitespace-pre-wrap sm:flex-row sm:gap-x-8 lg:gap-x-10">
            {MULTI_SELECT_SERVICE.includes(item.aprosCode)
              ? subShopServiceSubCheckbox(item, shopCode)
              : subShopServiceSubRadioButton(item, shopCode)}
          </div>
        </div>
        {/* 矢印部分 */}
        {!isSp && (
          <>
            <div className="triangle z-10 ml-[8px] mt-10"></div>
            <div className="absolute z-20 ml-[9px] mt-12 h-[1px] w-[7.6px] bg-background-main"></div>
          </>
        )}
      </>
    );

  const subShopServiceSubRadioButton = (item, shopCode) =>
    item.aprosSubCategories.map((sub, index) => (
      <RadioButton
        key={index}
        fieldName={`subShopServiceSub_${shopCode}`}
        label={createSubCategoryLabel(
          subShopServiceMain[shopCode],
          sub,
          'bg-white'
        )}
        checked={() => subShopServiceSub[shopCode] === sub.aprosSmallCode}
        value={`${shopCode}_${item.aprosCode}_${sub.aprosSmallCode}`}
        onChange={() => {
          setIsEdited(true);
          formik.setFieldValue('subShopServiceSub', {
            ...subShopServiceSub,
            [shopCode]: sub.aprosSmallCode,
          });
        }}
        className={{ labelClass: 'mx-0' }}
      />
    ));

  const subShopServiceSubCheckbox = (item, shopCode) =>
    item.aprosSubCategories.map(sub => (
      <Checkbox
        key={`${shopCode}_${item.aprosCode}_${sub.aprosSmallCode}`}
        fieldName={`${shopCode}_${item.aprosCode}_${sub.aprosSmallCode}`}
        text={sub.aprosSmallName}
        value={subShopServiceSub[shopCode].includes(sub.aprosSmallCode)}
        onChange={() => {
          setIsEdited(true);
          if (!subShopServiceSub[shopCode].includes(sub.aprosSmallCode)) {
            const newObject = { ...subShopServiceSub };
            newObject[shopCode].push(sub.aprosSmallCode);
            formik.setFieldValue('subShopServiceSub', newObject);
          } else {
            formik.setFieldValue('subShopServiceSub', {
              ...subShopServiceSub,
              [shopCode]: subShopServiceSub[shopCode].filter(
                l => l !== sub.aprosSmallCode
              ),
            });
          }
        }}
        className={{ labelClass: 'mx-0' }}
      />
    ));

  const { makerName, modelName } =
    vehicles.find(v => v.carSeq === selectedSrySeq) ?? {};

  const onClickReserve = (shopInfo, item, sub, serviceList) => {
    if (isSelectVehicleError()) return;
    const { chubunCd, shobun } = getCurrentService(serviceList, {
      ...item,
      aprosSubCategories: item.aprosSubCategories.filter(e =>
        sub.flat().includes(e.aprosSmallCode)
      ),
    });
    navigate(Path.RESERVATION_DATE_SELECT, {
      state: {
        yykInfo: {
          paramShopCode,
          location: currentLocation,
          paramPrefectures: prefectures,
          transitionPattern,
          srySeq: selectedSrySeq,
          sryMkrMei: makerName,
          sshMei: modelName,
          tokCd: shopInfo.shopCode,
          tokNmRyk: shopInfo.shopName,
          work: [{ chubunCd, shobun }],
        },
        stepLabels: REGISTER_SERVICE_STEP,
        baseScreen: Path.RESERVATION_SERVICE_SELECT,
        baseChubunCd: baseChubunCd,
      },
    });
  };

  // 地図で位置を確認リンク押下時
  const onClickMapLink = shop => {
    isSp && scrollTo(mapRef.current.offsetTop - 10);
    setIsEdited(true);
    // 再検索をしないのでマップの表示位置のみ更新する
    const [lng, lat] = shop.location;
    setMapCenter({ lat, lng });

    setSelectMarker(shop.shopCode);
  };

  const isSelectVehicleError = () => {
    if (!vehicles?.find(v => selectedSrySeq === v?.carSeq)?.modelName) {
      // 未登録車両の場合、登録できない
      dispatch(
        messageModalActions.showMessageModal({
          message:
            '未登録車両での予約はできません。\n新規車両を追加するか、車両を変更してください。',
        })
      );
      return true;
    }
    return false;
  };

  return (
    <>
      <Stepper
        labels={REGISTER_SERVICE_STEP}
        previousStep={previousStep || -1}
        activeStep={0}
        bottomElement={
          <ShopSelectBottomItem
            showSry={true}
            showService={false}
            vehicles={vehicles}
            selectedSrySeq={selectedSrySeq}
            onChangeSrySeq={v => {
              setIsEdited(true);
              dispatch(actions.setSrySeq(v));
            }}
            onClickNewSry={() => {
              setIsEdited(true);
              dispatch(actions.showVehicleRegisterModal());
            }}
          />
        }
        className={{
          stepperClass: 'mb-10 max-sm:mx-14 sm:mx-36',
          bottomClass: 'mx-0 h-full text-left lg:w-[800px]',
        }}
      />
      <div className="mt-12 flex flex-col gap-y-4 sm:gap-y-4 lg:flex-row lg:flex-wrap lg:gap-x-16">
        <Helmet>
          <title>{Title.RESERVATION_SERVICE_SELECT}</title>
        </Helmet>
        <Proctitle
          useH1={true}
          text="店舗・サービス選択"
          className={{ textClass: 'whitespace-nowrap' }}
        />
      </div>
      <UserNameLabel className={'mt-6'} />
      {RankDescription(rankCode)}
      {/* よくご利用いただく店舗 */}
      <div className="mt-8 sm:mt-10">
        <label className="text-base font-bold">{titleLabelName}</label>
        <div className="mt-4 flex flex-col rounded border border-line bg-white px-2 pt-4 sm:px-8">
          <div className="flex flex-col max-sm:px-2">
            <label className="text-base font-bold">
              {mainShopInfo?.shopName}
            </label>
            <label className="text-sm font-medium">
              {mainShopInfo &&
                `${mainShopInfo?.address1}${mainShopInfo?.address2}`}
            </label>
          </div>
          <div className="mt-4 sm:mt-6">
            {serviceSliceList.map((item, i) => (
              <div key={i}>
                <div className="mb-3 flex flex-row gap-x-2 sm:mb-4 sm:gap-x-4">
                  {item.map((e, i) => button(e, i))}
                </div>
                {item.map(e => e?.aprosCode).includes(serviceMain) &&
                  !NO_SMALL_SELECT_SERVICE.includes(serviceMain) && (
                    <div className="mb-3 flex h-auto w-full flex-row flex-wrap gap-x-6 whitespace-pre-wrap sm:mb-4 sm:gap-x-8 lg:gap-x-10">
                      {serviceList
                        .find(service => serviceMain === service.aprosCode)
                        .aprosSubCategories.map(e => serviceSubElement(e))}
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className="mb-4 mt-5 self-center sm:mb-8 sm:mt-4">
            <AprosButton
              text="予約へ進む"
              comClassName="reserve"
              disabled={!(serviceMain && serviceSub?.length > 0)}
              onClick={() =>
                onClickReserve(
                  {
                    shopCode: mainShopInfo.shopCode,
                    shopName: mainShopInfo.shopName,
                  },
                  serviceList.find(e => e.aprosCode === serviceMain),
                  [serviceSub],
                  serviceList
                )
              }
            />
          </div>
        </div>
      </div>
      {/* その他店舗 */}
      {showMapArea && (
        <div className="mt-10 sm:mt-16 lg:mt-24">
          <label ref={mapRef} className="text-base font-bold">
            店舗を探す
          </label>
          <div className="flex flex-col lg:flex-row">
            <div
              ref={element => setRef(element)}
              className="flex h-full w-full flex-col bg-background-sub px-4 py-6 sm:mt-4"
              style={{
                margin: isSp ? '8px calc(50% - 50vw) 0' : '',
                width: isSp ? '100vw' : '',
                flex: 10,
              }}
            >
              <div className="flex flex-row items-center">
                <label className="text-sm mr-2 font-medium sm:mr-4">
                  現在地から探す
                </label>
                <AprosButton
                  comClassName="sub"
                  disabled={isCurrentPlaceDisabled}
                  text={
                    <div className="flex flex-row items-center justify-center">
                      <Icons.Image src={Icons.mapNv} alt="現在地から探す" />
                      <label>現在地から探す</label>
                    </div>
                  }
                  onClick={() => {
                    setIsEdited(true);
                    formik.setFieldValue('prefectures', null);
                    getCurrentPosition();
                    isSp && scrollTo(mapRef.current.offsetTop - 10);
                  }}
                />
              </div>
              <div className="mt-6 flex flex-row flex-wrap items-center sm:mt-9">
                <label className="text-sm whitespace-nowrap pr-2 font-medium sm:mr-4">
                  エリアを選択してください
                </label>
                <InputSelect
                  formik={formik}
                  fieldName="prefectures"
                  placeholder="選択してください"
                  options={prefecturesList}
                  className={{ boxClass: 'w-40 p-0 sm:w-48' }}
                  isShowErrorArea={false}
                  changeAfter={v => {
                    isSp && scrollTo(mapRef.current.offsetTop - 10);
                    setIsEdited(true);
                    const { lat, lng } = prefecturesList.find(
                      p => p.value === v
                    );
                    setMapCenter({ lat, lng });
                  }}
                />
              </div>
              <Map
                markers={showSubShopInfoList?.map(sub => ({
                  lat: sub.location[1],
                  lng: sub.location[0],
                  shopName: sub.shopName,
                  shopCode: sub.shopCode,
                }))}
                center={mapCenter}
                selectMarker={selectMarker}
                disabled={isAroundPlaceDisabled}
                onClickMarker={({ shopCode, lat, lng }) => {
                  setIsEdited(true);
                  if (selectMarker === shopCode) {
                    setSelectMarker(null);
                  } else {
                    setMapCenter({ lat, lng });
                    setSelectMarker(shopCode);
                    // 初回表示時になぜかエラーになるのでsetTimeoutで囲う
                    setTimeout(() => {
                      subShopRefs?.current[
                        showSubShopInfoList
                          .map(s => s.shopCode)
                          .findIndex(s => s === shopCode)
                      ]?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                      });
                    }, 0);
                  }
                }}
                onClickSearch={({ lat, lng }) => {
                  // ボタン非活性
                  setIsAroundPlaceDisabled(true);

                  // 500ms待ち
                  setTimeout(() => {
                    setIsAroundPlaceDisabled(false);
                  }, 500);
                  setIsEdited(true);
                  formik.setFieldValue('prefectures', null);
                  updateLocation({ lat, lng });
                }}
              />
            </div>
            <div
              className=" mt-6 flex w-full flex-col sm:mt-4 lg:ml-4 lg:overflow-y-scroll lg:pr-2"
              style={{ height: mapAreaHeight, flex: 13 }}
            >
              {isSubShopLoading ? (
                <div className="flex h-full items-center justify-center">
                  <Icons.Image
                    src={Icons.loading}
                    className="h-[100px] w-[100px]"
                  />
                </div>
              ) : showSubShopInfoList.length === 0 ? (
                <>
                  <label className="text-sm whitespace-pre-wrap font-bold text-navy-light">
                    {`該当するメニューのお取り扱いがございません。${
                      isPc ? '' : '\n'
                    }別の店舗で検索してください。`}
                  </label>
                </>
              ) : (
                <>
                  <label className="text-sm w-full font-bold text-navy-light">
                    検索結果：{showSubShopInfoList?.length ?? 0}件
                  </label>
                  <ul>
                    {showSubShopInfoList.map((shop, i) => (
                      <li
                        ref={subShopRefs.current[i]}
                        key={shop.shopCode}
                        className={classNames(
                          'mt-2 flex w-full flex-col rounded border border-line px-2 pb-4 pt-3 sm:px-7 sm:pb-6 sm:pt-4 lg:px-8',
                          shop.shopCode === selectMarker
                            ? 'bg-background-selectShop'
                            : 'bg-white'
                        )}
                      >
                        <div className="flex flex-row items-center lg:justify-between">
                          <label className="text-base font-bold sm:mr-8 lg:mr-0">
                            {shop.shopName}
                          </label>
                          {!isSp && (
                            <MapLink onClick={() => onClickMapLink(shop)} />
                          )}
                        </div>
                        <label className="text-sm font-medium">
                          {shop.address}
                        </label>
                        {isSp && (
                          <MapLink onClick={() => onClickMapLink(shop)} />
                        )}
                        <div className="relative flex h-auto w-auto flex-col flex-wrap gap-x-6 whitespace-pre-wrap sm:flex-row sm:gap-x-8 lg:gap-x-10">
                          {subShopService?.[shop?.shopCode]?.map((item, i) => {
                            return (
                              <div key={i} className="flex flex-col">
                                {subShopServiceMainRadioButton(
                                  item,
                                  shop.shopCode
                                )}
                                {subShopSubArea(item, shop.shopCode)}
                              </div>
                            );
                          }) || <></>}
                        </div>
                        <div className="mt-5 self-center">
                          <AprosButton
                            text="予約へ進む"
                            comClassName="reserve"
                            disabled={
                              !(
                                subShopServiceMain[shop.shopCode] &&
                                subShopServiceSub[shop.shopCode]?.length > 0
                              )
                            }
                            onClick={() => {
                              onClickReserve(
                                shop,
                                subShopService[shop.shopCode].find(
                                  e =>
                                    e.aprosCode ===
                                    subShopServiceMain[shop.shopCode]
                                ),
                                [subShopServiceSub[shop.shopCode]],
                                subShopService[shop.shopCode]
                              );
                            }}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <VehicleRegisterModal
        isModalOpen={showVehicleRegisterModal}
        closeModal={() => dispatch(actions.closeVehicleRegisterModal())}
        carList={carList}
        onClick={async ({ manufacturer, carModel }) => {
          // 車両追加
          const sryMkrCd = manufacturer;
          const [sshCd, kshCd] = carModel.split('_');

          const maker = carList.find(c => c.sryMkrCd === sryMkrCd);
          const carInfo = maker.modelList.find(
            m => m.sshCd === sshCd && m.kshCd === kshCd
          );
          const params = {
            updateKbn: '1',
            kaiincrdNo: cardNumber,
            sryMkrCd,
            sryMkrMei: maker.sryMkrNm2,
            sshCd,
            sshMei: carInfo.sshNm2,
          };

          await dispatch(registerVehicles(params)).unwrap();

          dispatch(actions.closeVehicleRegisterModal());
        }}
      />

      <ShopUpdateModal
        isModalOpen={showShopChangeModal}
        shopList={shopList}
        onClickTop={() => navigate(Path.TOP)}
        onClickUpdate={async ({ shopCode }) => {
          await dispatch(
            updateUserShop({
              kbn: '2',
              kaiinNo: userId,
              kaiincrdNo: cardNumber,
              hskTenpCd: shopCode,
            })
          ).unwrap();
          dispatch(actions.closeShopChangeModal());
          dispatch(
            fetchServiceSelectInit({
              shopCode,
            })
          );
          if (vehicles.length === 1 && !vehicles[0].modelName) {
            // 未登録車両一つの場合、登録モーダルを表示する
            dispatch(actions.showVehicleRegisterModal());
          }
        }}
      />

      <NotesModal />
    </>
  );
};

export default ShopAndServiceSelect;
